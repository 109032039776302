<template>
  <v-container class="fill-height pt-0" fluid>

    <v-row class="d-flex flex-row py-1">

      <v-col cols="12" md="8" class="text-right px-0 mt-1">
        <div class="text-left black--text px-3">
          {{ $t("academic_education") }}
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-divider></v-divider>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" lg="6" xl="9"> </v-col>
      <v-col cols="12" md="6" lg="6" xl="3" class="text-right">
        <v-btn text color="primary" @click.prevent="dialog = !dialog">
          <v-icon left color="primary">mdi-plus-circle-outline</v-icon
          >{{ $t("new_academic_education") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-data-table :headers="headers" :items="items" class="elevation-1" dense>
          <template v-slot:item.en="{ item }">
            {{ item.text.en }}
          </template>
          <template v-slot:item.pt="{ item }">
            {{ item.text.pt }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn v-if="item.entity == currentUser.entity.id" color="primary" x-small fab class="ma-1" dark @click="edit(item)">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
            v-if="item.entity == currentUser.entity.id"
              color="error"
              x-small
              fab
              class="ma-1"
              dark
              @click="openConfirmeDialog(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <add-training-area-dialog
      :dialog="dialog"
      :trainingArea="trainingArea"
      :show="show"
      @close="closeAddDialog()"
    />

    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('remove_academic_education?')"
      @yes="deleteArea"
      @no="confirm = false"
    />

    <error-dialog :dialog="showError" :message="error" @close="showError = false" />

    <progress-dialog :processing="isLoading" />

    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import { DELETE_TRAINING_AREA_MUTATION } from "../graphql/Mutation";
import dialogMixins from "@/mixins/dialog";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import AddTrainingAreaDialog from "./../components/AddTrainingAreaDialog";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";

export default {
  name: "TrainingArea",
  components: {
    AddTrainingAreaDialog,
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
  },
  mixins: [dialogMixins, replaceSpecialCharsMixins],
  data: () => ({
    alert: false,
    textSearch: "",
    trainingArea: {
      text: {
        en: "",
        pt: "",
      },
    },
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTrainingAreas: "library/trainingAreas",
    }),
    items() {
      let resultItems = this.getTrainingAreas("all");

      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter(
          (t) =>
            this.replaceSpecialChars(t.text.en).includes(text) ||
            this.replaceSpecialChars(t.text.pt).includes(text)
        );
      }

      return resultItems;
    },
    headers() {
      return [
        {
          text: this.$t("english"),
          align: "start",
          sortable: false,
          value: "en",
        },
        {
          text: this.$t("portuguese"),
          align: "start",
          sortable: false,
          value: "pt",
        },
        { text: this.$t("actions"), align: "center", value: "actions" },
      ];
    },
  },
  methods: {
    ...mapActions({
      removeTrainingArea: "library/removeTrainingArea",
    }),
    async deleteArea(id) {
      this.confirm = false;
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_TRAINING_AREA_MUTATION,
          variables: {
            id,
          },
        });
        this.removeTrainingArea(id);
        this.success = this.$t("removed_academic_education_successfully", {
          entity: this.$t("academic_education"),
        });
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    edit(trainingArea) {
      this.trainingArea = {
        id: trainingArea.id,
        text: {
          en: trainingArea.text.en,
          pt: trainingArea.text.pt,
        },
      };
      this.dialog = true;
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      console.log(this.removeId);
      this.confirm = true;
    },
    closeAddDialog() {
      this.role = {
        text: {
          en: "",
          pt: "",
        },
      };
      this.show = false;
      this.dialog = false;
    },
  },
};
</script>
